import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
      <div className="footer">
          <p>anduda.tech@gmail.com</p>
          <p>2024</p>
    </div>
  );
};

export default Footer;